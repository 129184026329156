import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router';
import './Booklist.css'
import { config } from '../Constants';
import AddNewBook from './AddNewBook';


const BookDetails = () => {
    const [books, setBooks] = useState([]);
    const navigate= useNavigate();
    const handleBookAdded = (newBook) => {
        setBooks((prevBooks) => [...prevBooks, newBook]);
      };
    const [selectedBooks, setSelectedBooks] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    
    useEffect(() => {
        handleBookList()
    }, []);

    const handleRowClick = (event, id) => {
        if (event.target.type !== 'checkbox'){
         navigate(`/selectedbook/${id}`);
        }
    };

    const handleCheckboxChange = (event, bookId) => {
        if (event.target.checked) {
            setSelectedBooks(prevSelected => [...prevSelected, bookId]);
        } else {
            setSelectedBooks(prevSelected => prevSelected.filter(id => id !== bookId));
        }
    };

    const handleBookList = () => {
        axios.get(config.url.BASE_URL + '/api/v1/booksWithStatus').then(response => {
            setBooks(response.data.data);
        })
        .catch(error => {
            console.error('There was an error fetching the book details!', error);
        });
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedBooks([]);
        } else {
            setSelectedBooks(books.map(book => book.id));
        }
        setSelectAll(!selectAll);
    };
    
    const handleDisplaySelected = () => {
        axios.post(config.url.BASE_URL + '/api/v1/processSelectedBooks', selectedBooks, {
            headers: {
              'Content-Type':  'application/json',
            },
          })
          .then((response)=>{         
           if (response.status === 200 || response.status === 201) {   
                alert(response.data.data)
                handleBookList()
            }
        }) 
        .catch((error) =>{
          console.error('Error processing books:', error);
         });
    };

    return (
        <div>
            <div>
            <AddNewBook onBookAdded={handleBookAdded}  handleDisplaySelected={handleDisplaySelected} />
            </div>
            <div>
                <Table className='book-table'>
                    <TableHead className="table-head">
                        <TableRow >
                            {/* <TableCell className='table-cell'>Id</TableCell> */}
                            <TableCell>
                                <Checkbox
                                    checked={selectAll}
                                    indeterminate={selectedBooks.length > 0 && selectedBooks.length < books.length}
                                    onChange={handleSelectAllChange}
                                />
                                </TableCell>
                            <TableCell className='table-cell'>Title</TableCell>
                            {/* <TableCell className='table-cell'>Image</TableCell>
                            <TableCell className='table-cell'>Thumbnail</TableCell> */}
                            <TableCell className='table-cell'>Price</TableCell>
                            <TableCell className='table-cell'>Discount</TableCell>
                            <TableCell className='table-cell'>Rating</TableCell>
                            <TableCell className='table-cell'>Status</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {books.map((book, index) => (
                            // <TableRow key={index}>
                            <TableRow key={book.id}  className="table-row" onClick={(event) => handleRowClick(event, book.id)} style={{ cursor: 'pointer' }}>
                                {/*<TableCell className='table-cell'>{book.id}</TableCell> */}
                                <TableCell>
                                <Checkbox
                                    checked={selectedBooks.includes(book.id)}
                                    onChange={(event) => handleCheckboxChange(event, book.id)}
                                />
                                </TableCell>
                                <TableCell className='table-cell'>{book.title}</TableCell>
                                {/* <TableCell className='table-cell'>{book.image}</TableCell>
                                <TableCell className='table-cell'>{book.thumbnail}</TableCell> */}
                                <TableCell className='table-cell'>{book.price}</TableCell>
                                <TableCell className='table-cell'>{book.discount}</TableCell>
                                <TableCell className='table-cell'>{book.rating}</TableCell>
                                <TableCell className='table-cell'>{book.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};
export default BookDetails;