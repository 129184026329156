const prod = {
	url: {
		BASE_URL: 'https://sia.bajidev.duckdns.org'
	},
};

const dev = {
	url: {
		BASE_URL: 'https://sia.bajidev.duckdns.org'
	},
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;