import React, { useState } from 'react';
import { Grid,Button } from '@mui/material';

import './Home.css'; 
import LoginForm from './Login';
import SIABackGround from '../assets/SIABackGround.png'
import MenuBar from './MenuBar';
import SignupForm from './Signup';

const Home = () => {
  const [isLogin, setIsLogin] = useState(true);

  const handleSwitchToSignup = () => {
    setIsLogin(false);
  };

  const handleSwitchToLogin = () => {
    setIsLogin(true);
  };

  
  return (
    <div className="container">
      <div>
        <MenuBar onSwitchToLogin={handleSwitchToLogin}/>
      </div>
      <div className="row2">
        <Grid container className="container">
           <Grid item md={6} className="left-side">
            <div>
              <img src={SIABackGround} alt="sia image"  className="left-side"/>
            </div>
          </Grid>

            <Grid item md={6} className="right-side">
            <div className="form-toggle">
              {isLogin ? (
                <LoginForm onSwitchToSignup={handleSwitchToSignup}  />
              ) : (
                <SignupForm onSwitchToLogin={handleSwitchToLogin} />
              )}
            </div>
            </Grid>
        </Grid>
      </div>
      <div className="row3"></div>
    </div>
  );
};

export default Home;
