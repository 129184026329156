import { Route, Routes, BrowserRouter } from "react-router-dom";
import './App.css';
import LoginForm from "./compoents/Login";
import SignupForm from "./compoents/Signup";
import Home from "./compoents/Home";
import BookDetails from "./compoents/BookDetails";
import SelectedBook from "./compoents/SelectedBook";
import BookList from "./compoents/BookList";

function App() {
  return (
    <div className="App">
       <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path="/login" element={<LoginForm/>}/>
          <Route path="/signup" element={<SignupForm />} />   
          <Route path="/booklist" element={<BookList/>}  />      
          <Route path="/bookdetails" element={<BookDetails/>}  /> 
          <Route path="/selectedbook/:id" element={<SelectedBook />} />      
        </Routes>
      </BrowserRouter>      
    </div>
  );
}

export default App;
