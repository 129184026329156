import React from 'react'
import { AppBar, Toolbar, IconButton, Button } from '@mui/material';
import './Navbar.css';
import SIALogo from '../assets/SIALogo.png'
import Notification from '../assets/Notification.png'


const MenuBar = ({onSwitchToLogin}) => {
  

  return (
    <div>
        <AppBar position="static">
            <Toolbar className="header-toolbar">
                <IconButton edge="start" color="inherit" aria-label="logo">
                <img src={SIALogo} alt="sia logo" className="logo"/>
                </IconButton>

                <div>
                <Button className="app-button">Get the App</Button>
                <Button className="">
                    <img src={Notification} alt="sia notification" />
                </Button>
                <Button className="login-button" onClick={onSwitchToLogin}>Login</Button>
                </div>
            </Toolbar>
        </AppBar>
    </div>
  )
}

export default MenuBar