import React, { useState } from 'react';
import { TextField, Button, Typography, Container, FormControlLabel, Checkbox, Grid, Link } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import './Login.css';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { config } from '../Constants';

const LoginForm = ({onSwitchToSignup,  onSwitchToLogin}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    let tempErrors = {};
  
    if (!email) {
      tempErrors.email = "Email is required";
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      tempErrors.email = "Email is not valid";
    }
  
    if (!password) {
      tempErrors.password = "Password is required";
    } else if (password.length < 8) {
      tempErrors.password = "Password must be at least 8 characters long";
    }
  
    setError(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log({ email, password });
      setIsLoading(true);

      const userData = {
        email: email,
        password: password
      };
      axios.post(config.url.BASE_URL + '/api/v1/validateSystemUser', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          navigate('/booklist');
          setIsLoading(false);
        } else {
          alert('Something went wrong. Please try again.');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error submitting the form', error);
        alert(error.response.data.errorMessage);
        setIsLoading(false);

      });

    }
  };

  return (
    <Container component="main" maxWidth="xs" className="loginpage">
      <form onSubmit={handleSubmit} className='login'>
        
        <Typography variant="h6" >Login now</Typography>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors.email}
          helperText={errors.email}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors.password}
          fullWidth
          margin="normal"
        />
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  color="primary"
                />
              }
              label="Remember Me"
            />
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              Forgot Password?
            </Link>
          </Grid>
        </Grid>

        <Button variant="contained" color="primary" type="submit" fullWidth className="submitButton" onClick={onSwitchToLogin}>
        {isLoading ?      "Loading..."  : "Login" }      
          </Button>
        <Typography align="center" style={{ marginTop: '20px' }}>
          Not registered yet?{' '}Create an account {' '}
          <Link component={RouterLink} to="/" onClick={onSwitchToSignup} >
            Signup
          </Link>
        </Typography>
      </form>
    </Container>
  );
};

export default LoginForm;
