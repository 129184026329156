import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { TextField, MenuItem, Button, InputLabel, FormControl, Select, FormHelperText } from '@mui/material';
import { TextField, MenuItem, Button, FormControl, Select, Typography, FormHelperText } from '@mui/material';
import './SelectedBook.css'
import axios from 'axios';
import NavBar from './NavBar';
import { config } from '../Constants';
import { useNavigate } from 'react-router';


const SelectedBook = () => {
  const { id } = useParams();
  //const [setBooks] = useState([]);
  const navigate = useNavigate();
  const [books, setBooks] = useState([]);

  const [selectedBook, setSelectedBook] = useState({ id: '', title: '' });
  const [unitNumber, setUnitNumber] = useState('');
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [bookPartType,SetBookPartType] = useState('');
  const [isLoading, setIsLoading] = useState(false);

 
  useEffect(() => {
    axios.get(config.url.BASE_URL + '/api/v1/books')
      .then((response) => {
        setBooks(response.data.data);
        const currentBook = response.data.data.find(book => book.id === parseInt(id));
        setSelectedBook(currentBook ? { id: currentBook.id, title: currentBook.title } : { id: '', title: '' });
      })
      .catch((error) => {
        console.error('Error fetching the book details', error);
      });
  }, [id]);

  const validate = () => {
    let tempErrors = {};
    if (!bookPartType) tempErrors.bookPartType = "Book Part Type is required";
    if (!file) tempErrors.file = "Uploaded zip file is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    console.log(uploadedFile);
      if (uploadedFile && (uploadedFile.type === 'application/zip' || uploadedFile.type === 'application/x-zip-compressed')) {

      setFile(uploadedFile);
    } else {
      alert('Please upload a ZIP file only.');
      setFile(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validate()) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('bookId', selectedBook.id);
      formData.append('bookPartType', bookPartType);
      formData.append('unitNumber', unitNumber);
      formData.append('book', file);
  
      axios.post(config.url.BASE_URL + '/parse/processBookPart', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          alert('Book file uploaded successfully!');
          setIsLoading(false);
        } else {
          alert('Something went wrong. Please try again.');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        alert('There was an error submitting the book file.');
        setIsLoading(false);
      });
    }
  };

  

  return (
    <div>
      <div>
          <NavBar/>
      </div>
      <div>
        <form className="custom-form" onSubmit={handleSubmit}>
          {/* <div className="form-row">
            <label className="form-label">Book ID</label>
            <TextField
              value={selectedBook.id}
              InputProps={{
                readOnly: true,
              }}
              className="form-input"
            />
          </div> */}
          <div className="form-row">
             <label className="form-label">Book Title</label>
              <TextField
              value={selectedBook.title }
              InputProps={{
                readOnly: true,
              }}
              className="form-input"
            />
             
          </div>

          <div className="form-row">
            <label className="form-label">Book Part type</label>
            <FormControl className="form-input" error={!!errors.bookPartType}>
              <Select
                value={bookPartType}
                onChange={(e) => SetBookPartType(e.target.value)}
              >
                <MenuItem value="SHORT_QUESTION_ANSWER">Short-Question-Answer</MenuItem>
                <MenuItem value="LONG_QUESTION_ANSWER">Long-Question-Answer</MenuItem>
                <MenuItem value="SYLLABUS">Syllabus</MenuItem>
                <MenuItem value="FAQS">FAQ</MenuItem>
                <MenuItem value="MODEL_QP">Model_QP</MenuItem>
                <MenuItem value="EQPEXAM_QP">Model_EP</MenuItem>
              </Select>
              {errors.bookPartType && (
                <FormHelperText>{errors.bookPartType}</FormHelperText>
              )}
            </FormControl>
            
          </div>

          <div className="form-row">
            <label className="form-label">Unit</label>
            <TextField
              value={unitNumber}
              onChange={(e) => setUnitNumber(e.target.value)}
              className="form-input"
            />
          </div>

          <div className="form-row">
            <label className="form-label">Upload ZIP File</label>
            <div className="upload-container">
              <Button variant="contained" component="label" className="form-input" error={!!errors.file}>
                Upload ZIP File
                <input
                  type="file"
                  accept=".zip"
                  hidden
                  onChange={handleFileChange}
                />
                {errors.file && (
                    <Typography color="error" variant="body2">{errors.file}</Typography>
                  )}
              </Button>
                  {file && (
                    <div className="uploaded-file">
                    <Typography variant="body2">
                      Uploaded File: {file.name}
                    </Typography>
                  </div>
                  )}
              </div>
          </div>
          
          <div className="selectedbook-button">            
            <Button onClick={() => navigate(-1)} variant="contained" color="primary" >
            Back</Button> 
            <Button type="submit" variant="contained" color="primary" className="form-submit">
            {isLoading ? "Loading..." : "Submit" }
            </Button>
          </div>
      </form>
      </div>     
    </div>
  );
};

export default SelectedBook;
