import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FileBase64 from 'react-file-base64';
import axios from 'axios';
import { config } from '../Constants';
import './AddNewBook.css';

function AddNewBook({ onBookAdded, handleDisplaySelected }) {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setError] = useState({});
    const [formData, setFormData] = useState({
        title: '',
        price: '',
        discount: '',
        publicationDate: '',
        summary: '',
        rating: '',
        image: null,
        thumbnail: null
    });
    


  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const validateFormData = () => {
    let tempErrors = {};
  
    if (!setFormData.title) {
      tempErrors.title = "Title is required";
    }
  
    if (!setFormData.rating) {
      tempErrors.rating = "Rating is required";
    }

    if (!setFormData.price) {
      tempErrors.price = "Price is required";
    }

    if (!setFormData.image) {
      tempErrors.image = "Image is required";
    }
  
    setError(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleClose = () => {
    setFormData({
      title: '',
      price: '',
      discount: '',
      publicationDate: '',
      summary: '',
      rating: '',
      image: null,
      thumbnail: null
  });

    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleFileUpload = (file, field) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: file.base64,
      [`${field}Name`]: file.name 
    }));
  };

  const handleSubmit = () => {
    if(validateFormData()) {
      setIsLoading(true);
      const submissionData = new FormData();

      submissionData.append('price', Number(formData.price));
      submissionData.append('discount', Number(formData.discount));
      
      for (let key in formData) {
        if (key !== 'price' && key !== 'discount') {
          submissionData.append(key, formData[key]);
        }
      }
      for (let pair of submissionData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
      //axios.post(config.url.BASE_URL + '/api/v1/books', submissionData, {
        axios.post('http://localhost:9000/api/v1/books', submissionData, {
          headers: {
            'Content-Type':  'application/json',
          },
        })
        .then((response)=>{     
          setIsLoading(true);

          if (response.status === 200 || response.status === 201) {
            onBookAdded(response.data);
            handleClose();
            setIsLoading(false);
          }
      }) 
      .catch((error) =>{
        console.error('Error adding book:', error);
        alert("Unable to Save book details. Please contact Administrator.");
        setIsLoading(false);
      });

    }
    
  };


  return (
    <div>
        <div className="new-book-button-container">
          <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDisplaySelected}
          > Process Uploaded Books Again
          </Button>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen}>
            New Book
          </Button>
        </div>
        

      <Modal open={open} onClose={handleClose}  disableScrollLock={true}>
        <Box className="modal-box">
          <Typography variant="h6" component="h2" gutterBottom>
            Add New Book
          </Typography>
          <TextField
            label="Title"
            name="title"
            variant="outlined"
            error={!!errors.title}
            helperText={errors.title}
            fullWidth
            margin="normal"
            value={formData.title}
            onChange={handleChange}
          />
          <TextField
            label="Price"
            name="price"
            variant="outlined"
            error={!!errors.price}
            helperText={errors.price}
            fullWidth
            margin="normal"
            type="number"
            value={formData.price}
            onChange={handleChange}
          />
          <TextField
            label="Discount"
            name="discount"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={formData.discount}
            onChange={handleChange}
          />
          <TextField
            label="Publication Date"
            name="publicationDate"
            variant="outlined"
            fullWidth
            margin="normal"
            type="date"
            value={formData.publicationDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Summary"
            name="summary"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.summary}
            onChange={handleChange}
          />
          <TextField
            label="Rating"
            name="rating"
            variant="outlined"
            error={!!errors.rating}
            helperText={errors.rating}
            fullWidth
            margin="normal"
            type="number"
            inputProps={{ min: 0, max: 5, step: 0.1 }}
            value={formData.rating}
            onChange={handleChange}
          />
          <TextField
            label="Image"
            name="imageName"
            variant="outlined"
            error={!!errors.image}
            helperText={errors.image}
            fullWidth
            margin="normal"
            value={formData.imageName || 'Choose file'}
            InputProps={{
              readOnly: true,
            }}
          />
          <FileBase64
            multiple={false}
            onDone={(file) => handleFileUpload(file, 'image')}
          />
          
          <TextField
            label="Thumbnail"
            name="thumbnailName"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.thumbnailName || 'Choose file'}
            InputProps={{
              readOnly: true,
            }}
          />
          <FileBase64
            multiple={false}
            onDone={(file) => handleFileUpload(file, 'thumbnail')}
          />
          <Box className="modal-actions">
            <Button onClick={handleClose} color="secondary" sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              {isLoading ? "Loading..."  : "Save" } 
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default AddNewBook;
