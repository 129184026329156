import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Link } from '@mui/material';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Constants';
import { CleaningServices } from '@mui/icons-material';


const SignupForm = ({onSwitchToLogin}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);


  const validate = () => {
    let tempErrors = {};
    if (!formData.firstName) tempErrors.firstName = "First name is required";
    if (!formData.lastName) tempErrors.lastName = "Last name is required";
    if (!formData.email) tempErrors.email = "Email is required";
    if (!formData.phone) tempErrors.phone = "Phone number is required";
    if (!formData.password) tempErrors.password = "Password is required";
    if (formData.password !== formData.confirmPassword) tempErrors.confirmPassword = "Passwords do not match";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // console.log({ username, email, password });
      setIsLoading(true);
      const userData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: `+91${formData.phone}`,
        password: formData.password,
      };
      axios.post(config.url.BASE_URL + '/api/v1/systemUserRegistration', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          alert('Login successfully completed. Please login.');
          onSwitchToLogin();
          setIsLoading(false);
        } else {
          alert('Something went wrong. Please try again.');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error submitting the form', error);
        alert(error.response.data.errorMessage);
        onSwitchToLogin();
        setIsLoading(false);
      });
    }
  };

  return (
    <Container maxWidth="xs" className='signup'>
      <form onSubmit={handleSubmit}>
        <Typography variant="h6" align="center">Sign Up</Typography>
        <TextField
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          error={!!errors.firstName}
          helperText={errors.firstName}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          error={!!errors.lastName}
          helperText={errors.lastName}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone Number"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          error={!!errors.phone}
          helperText={errors.phone}
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: '+91 ',
          }}
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          value={formData.confirmPassword}
          onChange={handleChange}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" type="submit" fullWidth>
        {isLoading ? "Loading..." : "Sign up" }   
        </Button>
        <Typography align="center" style={{ marginTop: '20px' }}>
          Already have an account?{' '}
          <Link component={RouterLink} to="/" onClick={onSwitchToLogin}>
            Login
          </Link>
        </Typography>
      </form>
    </Container>
  );
};

export default SignupForm;
