import React from 'react';
import { AppBar, Toolbar, IconButton, Button, Avatar, Menu, MenuItem } from '@mui/material';
import './Navbar.css';
import SIALogo from '../assets/SIALogo.png'
// import { useNavigate } from 'react-router';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';


const NavBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate =useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null); 
  };

  return (
    <AppBar position="static">
      <Toolbar className="header-toolbar">
        <IconButton edge="start" color="inherit" aria-label="logo">
          <img src={SIALogo} alt="sia logo"  className="logo"/>
        </IconButton>
  
        <div>
        <Button className="app-button">Get the App</Button>
          <IconButton onClick={handleMenu} >
            <Avatar alt="Profile Picture" src="path_to_profile_pic.jpg" className="profile-avatar" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem component={RouterLink} to="/">Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
