import React from 'react'
import BookDetails from './BookDetails'
import NavBar from './NavBar'

const BookList = () => {
  return (
    <div>
      <div>
        <NavBar/>
      </div>
      <div>        
        <div>
          <BookDetails/>
        </div>        
      </div>       
    </div>
  )
}

export default BookList